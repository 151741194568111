.signup-success-wrapper,
.signup-wrapper { background-color: transparent; }
.signup-success-modal,
.signup-modal { background-color: transparent; }

.click-to-top span{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333;
    color: #fff;
}
.click-to-top:hover span {
    display: block;
}

.folding-map {
    display: inline-block;
}

.folding-map .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 98%;
}

.folding-map .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
  }

.folding-map:hover .tooltiptext {
    visibility: visible;
}