#wall {
  position: relative;
  width: 100%;
  height: 150px;
  top: -10px;
  background: #fff;
  text-align: center;
  padding-top: 30px;
  font-family: Quicksand, Helvetica, sans;
  color: #FF5700;
}

#eye-l,
#eye-r {
  position: absolute;
  z-index: 20;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  margin-top: 5px;
  left: 5px;
  margin-left: -5px;
  -webkit-animation: search 2s infinite;
  box-sizing: border-box;
  border: 3px solid #FF5700;
}

#eye-r {
  margin-left: 5px;
}

#nose {
  position: relative;
  width: 15px;
  height: 15px;
  border: 3px solid #FF5700;
  border-radius: 50%;
  border-top-color: transparent;
  background: white;
  top: 12px;
  left: -7px;
  -webkit-transform: rotate(35deg);
  -webkit-animation: noser 2s infinite;
}

#mouth {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #FF5700;
  margin-top: 20px;
  float: left;
  margin-left: 3px;
  -webkit-animation: search 2s infinite;
}

#man {
  position: relative;
  width: 50px;
  height: 70px;
  border: 5px solid #FF5700;
  border-radius: 50%;
  margin-left: 50%;
  left: -25px;
  -webkit-animation: pop 8s infinite;
}

@-webkit-keyframes search {
  0%,
  100% {
    -webkit-transform: translate(0px, 0px);
  }
  50% {
    -webkit-transform: translate(32px, 0px);
  }
}

@-webkit-keyframes noser {
  0%,
  100% {
    -webkit-transform: translate(0px, 0px) rotate(35deg);
  }
  50% {
    -webkit-transform: translate(43px, 0px) rotate(-35deg);
  }
}

@-webkit-keyframes pop {
  0%,
  100% {
    -webkit-transform: translate(0px, 80px);
  }
  20%,
  80% {
    -webkit-transform: translate(0px, 10px);
  }
}
