.static-title-bar {
  border-bottom: 1px solid grey;
  min-height: 144px;
  color: rgb(66, 66, 66);
  margin-top: 10px;
  text-align: "center";
  padding-right: 20;
}

.static-column {
  text-align: center;
  color: rgb(61, 61, 85);
}

.highlighted-title {
  color: #c7543d;
  font-size: 25px;
}

.affiliated-link {
  color: black;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.tag-line {
  font-size: 30px;
}

.tag-line-second {
  font-size: 20px;
}

.affiliated-link .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  margin-left: -110px;
}

.affiliated-link .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.affiliated-link:hover .tooltiptext {
  visibility: visible;
}

.dropdown-content {
  background-color: #f9f9f9;
  min-width: 300px;
  margin: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 160px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(250, 250, 250, 0);
}
.contained{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}