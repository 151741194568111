* {
  /* background-color: #fffdf3; */
  padding-right: 0px;
  padding-left: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card-brand {
  background-color: #fff;
  width: 100%;
  height: 96px;
  border-radius: 16px;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
}

.organization-container {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(5, 170px);
  justify-content: center;
}

.right-grids {
  position: relative;
  top: 50px;
}

.grid-col-1 {
  grid-column: 1;
}

.grid-col-2 {
  grid-column: 2;
}

.grid-col-3 {
  grid-column: 3;
}

@media screen and (max-width: 1000px) {
  .organization-container {
    grid-template-columns: repeat(3, 170px);
  }

  .right-grids {
    position: unset;
    top: unset;
  }

  .grid-col-1 {
    grid-column: unset;
  }

  .grid-col-2 {
    grid-column: unset;
  }

  .grid-col-3 {
    grid-column: unset;
  }
}

.mainPAgeIframe {
  width: 909px;
  height: 466px;
}

@media screen and (max-width: 900px) {
  .mainPAgeIframe {
    width: 600px;
    height: 400px;
  }
}

@media screen and (max-width: 620px) {
  .organization-container {
    grid-template-columns: repeat(2, 170px);
  }

  .mainPAgeIframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 390px) {
  .organization-container {
    grid-template-columns: repeat(1, 170px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* from Tufte's work */
h1,
h2,
h3,
h4 {
  font-family: "Gill Sans W04 Roman";
  text-transform: uppercase;
  color: rgb(220, 40, 40);
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}
h1 {
  font-size: 19px;
}
h2 {
  font-size: 17px;
}
h3 {
  font-size: 15px;
}
h4 {
  font-size: 14px;
}

/* The following two change the spacing on Safari */

@media not all and (min-resolution: 0.001dpcm) {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 0px;
  }
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 0px;
  }
}

.section-title {
  font-family: "Gill Sans W04 Roman";
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(220, 40, 40);
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.2;
  padding-bottom: 5px;
  text-align: center;
}

#content {
  max-width: 1040px;
  text-align: left;
  margin: 10px 15px;
}

#navbar {
  display: inline-block;
  max-width: 1015px;
  box-sizing: border-box;
  font-family: "GillSans", "Gill Sans MT", "Gill Sans", "Trebuchet MS",
    sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 14px;
  margin-top: 0px;
  padding-left: 24px;
}

#responsive_navbar {
  display: none;
  box-sizing: border-box;
}

#navbar .links {
  display: table-row;
  color: red;
}

#navbar .box {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: middle;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0px 9px;
}

#navbar .first_box {
  padding-left: 0px;
}

#navbar .link,
#responsive_navbar .link {
  color: rgb(66, 66, 66);
  text-decoration: none;
  vertical-align: top;
}

#navbar .link:hover {
  color: rgb(220, 45, 21);
}

#navbar .social_links {
  padding-left: 20px;
  padding-right: 0px;
  text-align: right;
}

#navbar .cart_link {
  float: left;
  padding-right: 3px;
  margin-top: -1px;
}

#navbar .search_line {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  padding-top: 5px;
  padding-right: 3px;
}

@media (max-width: 700px) {
  #navbar {
    width: 100%;
    margin-bottom: 20px;
    display: none;
    font-size: 16px;
    line-height: 26px;
  }

  #navbar .box {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #999;
  }

  #navbar .social_links,
  #navbar .search_line {
    display: none;
  }

  #responsive_navbar {
    display: table;
    width: 100%;
    padding: 0px;
    margin: 0px 0px 15px 0px;
  }

  #responsive_navbar_left {
    display: table-cell;
    font-size: 16px;
    font-family: GillSans, "Gill Sans MT", "Gill Sans", "Trebuchet MS",
      sans-serif;
    color: rgb(66, 66, 66);
    vertical-align: middle;
  }

  #responsive_navbar_right {
    display: table-cell;
    text-align: right;
  }
}

@media (max-width: 450px) {
  #content {
    margin: 0px 5px 0px 5px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.forum {
  color: #cc0000;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 15px;
}

.text {
  font-size: 15px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.textsm {
  font-size: 14px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.textxsm {
  font-size: 13px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.link {
  text-decoration: none;
  color: #cc0000;
}
.link:hover {
  text-decoration: underline;
  color: #cc0000;
}
.belink {
  text-decoration: none;
  color: #000000;
}
.belink:hover {
  text-decoration: underline;
  color: #cc0000;
}
.taglink {
  margin-top: 4pt;
}
.smalltext {
  font-family: "Times New Roman", "Times", "Serif";
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.bboard_image_credit {
  font-size: 10pt;
  color: #000000;
  font-family: Times, Arial, Helvetica, sans-serif;
  line-height: 21px;
}

.search_box {
  width: 205px;
  font-size: 11px;
  font-family: "GillSans", "Gill Sans MT", "Gill Sans", "Trebuchet MS",
    sans-serif;
  color: #999999;
}
#controlled-tab-example > Tab {
  color: green;
}
/* .tabMenu ul > li > a {
  color: #000000;
}
.myClass Tabs > Tab > a:hover {
  color: #cc0000;
  text-decoration-line: #cc0000;
}

/* Active tab */
.myclass > .active > a,
.myclass > .active > a:hover,
.myclass > .active > a:focus {
  color: #cc0000;
}
/* Set height of body and the document to 100% to enable "full page tabs" */
body,
html {
  height: 100%;
  margin: 0;
  background-color: white;
}

/* Style tab links */
.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
}

.tablink:hover {
  background-color: #777;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 100px 20px;
  height: 100%;
  background-color: white;
}

#Home {
  background-color: red;
}
#News {
  background-color: green;
}
#Contact {
  background-color: blue;
}
#About {
  background-color: orange;
}
@import url("https://fonts.googleapis.com/css?family=Lobster");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

/* react-pdf */
.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__canvas {
  margin: 4px auto;
  box-shadow: 0 0 10px 5px #ccc;
  border-radius: 5px;
}

.pdf-button {
  margin: 4px;
  background: #3f51b5;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
}

.SubStack-iframe {
  width: 600px;
}

@media (max-width: 700px) {
  .SubStack-iframe {
    width: 460px;
  }
} 

@media (max-width: 400px) {
  .SubStack-iframe {
    width: 320px;
  }
} 

.hero_section_heading :is(strong) {
  color:rgb(199 84 76);
}