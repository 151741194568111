@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
* {
  /* background-color: #fffdf3; */
  padding-right: 0px;
  padding-left: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.card-brand {
  background-color: #fff;
  width: 100%;
  height: 96px;
  border-radius: 16px;
  box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
}

.organization-container {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: repeat(5, 170px);
  justify-content: center;
}

.right-grids {
  position: relative;
  top: 50px;
}

.grid-col-1 {
  grid-column: 1;
}

.grid-col-2 {
  grid-column: 2;
}

.grid-col-3 {
  grid-column: 3;
}

@media screen and (max-width: 1000px) {
  .organization-container {
    grid-template-columns: repeat(3, 170px);
  }

  .right-grids {
    position: unset;
    top: unset;
  }

  .grid-col-1 {
    grid-column: unset;
  }

  .grid-col-2 {
    grid-column: unset;
  }

  .grid-col-3 {
    grid-column: unset;
  }
}

.mainPAgeIframe {
  width: 909px;
  height: 466px;
}

@media screen and (max-width: 900px) {
  .mainPAgeIframe {
    width: 600px;
    height: 400px;
  }
}

@media screen and (max-width: 620px) {
  .organization-container {
    grid-template-columns: repeat(2, 170px);
  }

  .mainPAgeIframe {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 390px) {
  .organization-container {
    grid-template-columns: repeat(1, 170px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* from Tufte's work */
h1,
h2,
h3,
h4 {
  font-family: "Gill Sans W04 Roman";
  text-transform: uppercase;
  color: rgb(220, 40, 40);
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}
h1 {
  font-size: 19px;
}
h2 {
  font-size: 17px;
}
h3 {
  font-size: 15px;
}
h4 {
  font-size: 14px;
}

/* The following two change the spacing on Safari */

@media not all and (min-resolution: 0.001dpcm) {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 0px;
  }
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 0px;
  }
}

.section-title {
  font-family: "Gill Sans W04 Roman";
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(220, 40, 40);
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.2;
  padding-bottom: 5px;
  text-align: center;
}

#content {
  max-width: 1040px;
  text-align: left;
  margin: 10px 15px;
}

#navbar {
  display: inline-block;
  max-width: 1015px;
  box-sizing: border-box;
  font-family: "GillSans", "Gill Sans MT", "Gill Sans", "Trebuchet MS",
    sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 14px;
  margin-top: 0px;
  padding-left: 24px;
}

#responsive_navbar {
  display: none;
  box-sizing: border-box;
}

#navbar .links {
  display: table-row;
  color: red;
}

#navbar .box {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: middle;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0px 9px;
}

#navbar .first_box {
  padding-left: 0px;
}

#navbar .link,
#responsive_navbar .link {
  color: rgb(66, 66, 66);
  text-decoration: none;
  vertical-align: top;
}

#navbar .link:hover {
  color: rgb(220, 45, 21);
}

#navbar .social_links {
  padding-left: 20px;
  padding-right: 0px;
  text-align: right;
}

#navbar .cart_link {
  float: left;
  padding-right: 3px;
  margin-top: -1px;
}

#navbar .search_line {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  padding-top: 5px;
  padding-right: 3px;
}

@media (max-width: 700px) {
  #navbar {
    width: 100%;
    margin-bottom: 20px;
    display: none;
    font-size: 16px;
    line-height: 26px;
  }

  #navbar .box {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #999;
  }

  #navbar .social_links,
  #navbar .search_line {
    display: none;
  }

  #responsive_navbar {
    display: table;
    width: 100%;
    padding: 0px;
    margin: 0px 0px 15px 0px;
  }

  #responsive_navbar_left {
    display: table-cell;
    font-size: 16px;
    font-family: GillSans, "Gill Sans MT", "Gill Sans", "Trebuchet MS",
      sans-serif;
    color: rgb(66, 66, 66);
    vertical-align: middle;
  }

  #responsive_navbar_right {
    display: table-cell;
    text-align: right;
  }
}

@media (max-width: 450px) {
  #content {
    margin: 0px 5px 0px 5px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.forum {
  color: #cc0000;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 15px;
}

.text {
  font-size: 15px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.textsm {
  font-size: 14px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.textxsm {
  font-size: 13px;
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 21px;
}
.link {
  text-decoration: none;
  color: #cc0000;
}
.link:hover {
  text-decoration: underline;
  color: #cc0000;
}
.belink {
  text-decoration: none;
  color: #000000;
}
.belink:hover {
  text-decoration: underline;
  color: #cc0000;
}
.taglink {
  margin-top: 4pt;
}
.smalltext {
  font-family: "Times New Roman", "Times", "Serif";
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.bboard_image_credit {
  font-size: 10pt;
  color: #000000;
  font-family: Times, Arial, Helvetica, sans-serif;
  line-height: 21px;
}

.search_box {
  width: 205px;
  font-size: 11px;
  font-family: "GillSans", "Gill Sans MT", "Gill Sans", "Trebuchet MS",
    sans-serif;
  color: #999999;
}
#controlled-tab-example > Tab {
  color: green;
}
/* .tabMenu ul > li > a {
  color: #000000;
}
.myClass Tabs > Tab > a:hover {
  color: #cc0000;
  text-decoration-line: #cc0000;
}

/* Active tab */
.myclass > .active > a,
.myclass > .active > a:hover,
.myclass > .active > a:focus {
  color: #cc0000;
}
/* Set height of body and the document to 100% to enable "full page tabs" */
body,
html {
  height: 100%;
  margin: 0;
  background-color: white;
}

/* Style tab links */
.tablink {
  background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
}

.tablink:hover {
  background-color: #777;
}

/* Style the tab content (and add height:100% for full page content) */
.tabcontent {
  color: white;
  display: none;
  padding: 100px 20px;
  height: 100%;
  background-color: white;
}

#Home {
  background-color: red;
}
#News {
  background-color: green;
}
#Contact {
  background-color: blue;
}
#About {
  background-color: orange;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

/* react-pdf */
.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__canvas {
  margin: 4px auto;
  box-shadow: 0 0 10px 5px #ccc;
  border-radius: 5px;
}

.pdf-button {
  margin: 4px;
  background: #3f51b5;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
}

.SubStack-iframe {
  width: 600px;
}

@media (max-width: 700px) {
  .SubStack-iframe {
    width: 460px;
  }
} 

@media (max-width: 400px) {
  .SubStack-iframe {
    width: 320px;
  }
} 

.hero_section_heading :is(strong) {
  color:rgb(199 84 76);
}
.static-title-bar {
  border-bottom: 1px solid grey;
  min-height: 144px;
  color: rgb(66, 66, 66);
  margin-top: 10px;
  text-align: "center";
  padding-right: 20;
}

.static-column {
  text-align: center;
  color: rgb(61, 61, 85);
}

.highlighted-title {
  color: #c7543d;
  font-size: 25px;
}

.affiliated-link {
  color: black;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.tag-line {
  font-size: 30px;
}

.tag-line-second {
  font-size: 20px;
}

.affiliated-link .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  margin-left: -110px;
}

.affiliated-link .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.affiliated-link:hover .tooltiptext {
  visibility: visible;
}

.dropdown-content {
  background-color: #f9f9f9;
  min-width: 300px;
  margin: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 160px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(250, 250, 250, 0);
}
.contained{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dynamic-header {
    border-bottom: 1px solid grey;
}
.sf-1 .dd {
  width: 100%;
}
.sf-1 .dd-toggle {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.sf-1 .dd-menu {
  width: 100%;
}
@media (min-width: 576px) {
  .sf-1 .dd {
    width: 65%;
  }
}
@media (min-width: 992px) {
  .sf-1 .dd {
    width: 50%;
  }
}
.regular-checkbox {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
.regular-checkbox:checked:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
}

.big-checkbox {
  padding: 18px;
}

.big-checkbox:checked:after {
  font-size: 28px;
  left: 6px;
}

.sf-2 .dd-row { display: flex; flex-direction: column; }
.sf-2 .dd { width: 100%; margin-bottom: 1em; }
.sf-2 .dd-toggle { width: 100%; display: flex !important; align-items: center; justify-content: space-between; }
.sf-2 .dd-menu { width: 100%; }
.sf-2 .dd-back-btn { width: 50%; }

@media (min-width: 576px) {
    .sf-2 .dd-row { flex-direction: row; justify-content: space-between; align-items: center; }
    .sf-2 .dd { width: 65%; margin-bottom: unset; }
    .sf-2 .dd-back-btn { width: unset; }
}
@media (min-width: 992px) {
    .sf-2 .dd { width: 50%; }
}
body {
  background: #fff;
  padding: 0;
  margin: 0;
}
@media (min-width: 0px) {
  .coreSupporterComment {
    position: absolute;
    max-width: 70%;
    left: 74px;
    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 470px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 94px;

    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 520px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 94px;
    transform: translate(0px, -58px);
    float: left;
  }
}

.coreSupporterComment #holder {
  width: 100%;
}

#holder > div {
  clear: both;
  padding: 0%;
  margin-left: 2px;
  margin-bottom: 0px;

  float: left;
  width: 96%;
}

label {
  display: inline;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fffdf3;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;

  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox + label:active,
.regular-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked + label {
  background-color: #fffdf3;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #4d4d4d;
}

.regular-checkbox:checked + label:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 5px;
  color: #4d4d4d;
}

.big-checkbox + label {
  padding: 18px;
}

.big-checkbox:checked + label:after {
  font-size: 28px;
  left: 6px;
}

.tag {
  font-family: Arial, sans-serif;
  width: 200px;
  position: relative;
  top: 5px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  float: left;
}

.radio-1 {
  width: 193px;
}

.button-holder {
  float: left;
}

/* RADIO */

.regular-radio {
  display: none;
}

.regular-radio + label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}

.regular-radio:checked + label:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #99a1a7;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-shadow: 0px;
  left: 3px;
  font-size: 32px;
}

.regular-radio:checked + label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1),
    inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.regular-radio + label:active,
.regular-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.big-radio + label {
  padding: 16px;
}

.big-radio:checked + label:after {
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px;
}

/* ------- IGNORE */

#header {
  width: 100%;
  margin: 0px auto;
}

#header #center {
  text-align: center;
}

#header h1 span {
  color: #000;
  display: block;
  font-size: 50px;
}

#header p {
  font-family: "Georgia", serif;
}
#header h1 {
  color: #892dbf;
  font: bold 40px "Bree Serif", serif;
}

#travel {
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  font-feature-settings: normal;
  font-variant: normal;
  text-decoration: none;
  margin-bottom: 20px;
}

#travel a {
  font-family: "Georgia", serif;
  text-decoration: none;
  border-bottom: 1px solid #f9f9f9;
  color: #f9f9f9;
}

.signup-success-wrapper,
.signup-wrapper { background-color: transparent; }
.signup-success-modal,
.signup-modal { background-color: transparent; }

.click-to-top span{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333;
    color: #fff;
}
.click-to-top:hover span {
    display: block;
}

.folding-map {
    display: inline-block;
}

.folding-map .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 98%;
}

.folding-map .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
  }

.folding-map:hover .tooltiptext {
    visibility: visible;
}
.login-success-wrapper,
.login-wrapper {
    background-color: transparent;
}

.login-success-modal,
.login-modal {
    background-color: transparent;
   
}




.change-userName-form {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .change-userName-form {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .change-userName-form {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .change-userName-form {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .change-userName-form {
    width: 50%;
  }
}

body {
  background: #fff;
  padding: 0;
  margin: 0;
}
@media (min-width: 0px) {
  .coreSupporterComment {
    position: absolute;
    max-width: 70%;
    left: 74px;
    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 470px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 94px;

    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 520px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 104px;
    transform: translate(0px, -58px);
    float: left;
  }
}

.coreSupporterComment #holder {
  width: 100%;
}

#holder > div {
  clear: both;
  padding: 0%;
  margin-left: 2px;
  margin-bottom: 0px;

  float: left;
  width: 96%;
}

label {
  display: inline;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fffdf3;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;

  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox + label:active,
.regular-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked + label {
  background-color: #fffdf3;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #4d4d4d;
}

.regular-checkbox:checked + label:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 5px;
  color: #4d4d4d;
}

.big-checkbox + label {
  padding: 18px;
}

.big-checkbox:checked + label:after {
  font-size: 28px;
  left: 6px;
}

.tag {
  font-family: Arial, sans-serif;
  width: 200px;
  position: relative;
  top: 5px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  float: left;
}

.radio-1 {
  width: 193px;
}

.button-holder {
  float: left;
}

/* RADIO */

.regular-radio {
  display: none;
}

.regular-radio + label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}

.regular-radio:checked + label:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #99a1a7;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-shadow: 0px;
  left: 3px;
  font-size: 32px;
}

.regular-radio:checked + label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1),
    inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.regular-radio + label:active,
.regular-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.big-radio + label {
  padding: 16px;
}

.big-radio:checked + label:after {
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px;
}

/* ------- IGNORE */

#header {
  width: 100%;
  margin: 0px auto;
}

#header #center {
  text-align: center;
}

#header h1 span {
  color: #000;
  display: block;
  font-size: 50px;
}

#header p {
  font-family: "Georgia", serif;
}
#header h1 {
  color: #892dbf;
  font: bold 40px "Bree Serif", serif;
}

#travel {
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  font-feature-settings: normal;
  font-variant: normal;
  text-decoration: none;
  margin-bottom: 20px;
}

#travel a {
  font-family: "Georgia", serif;
  text-decoration: none;
  border-bottom: 1px solid #f9f9f9;
  color: #f9f9f9;
}

.parent-block {
    border-radius: 5px;
    border: 1px solid black;
    display: flex;
    padding: 2px !important;
    margin-left: 10px !important;
    height: 46px;
    max-height: 46px;
    min-height: 46px;
}

.parent-block .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 102%;
}

.parent-block .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
  }

.parent-block:hover .tooltiptext {
    visibility: visible;
}
  

.text-block {
    width: calc(100% - 40px);
    margin: 0;
    background-color: inherit;
    color: inherit;
}

.comment-block {
    height: 20%;
    font-size: 11px;
    margin-left: 3px;
    background-color: inherit;
    color: inherit;
}

.innerText-block {
    height: calc(80% - 5px);
    font-size: 18px;
    margin-top: 2px;
    margin-left: 7px;
    background-color: inherit;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.arrow {
    margin: auto;
    font-size: 26px;
    position: absolute;
    right: 4%;
    background-color: inherit;
    color: inherit;
}

.arrow-opened {
    right: 12%;
    transform: rotate(90deg);
    background-color: inherit;
    color: inherit;
}

.arrow-block {
    width: 40px;
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: inherit;
    color: inherit;
}
.percent-block {
    color: black;
    font-size: 20px;
    text-align: right;
}
.supporter-number {
    color: red;
    font-size: 20px;
    text-align: right;
}
.level-bar-goal {
    background-color: yellow;
}

.title-bar {
    color: #3f007d;
    margin-left: 5px,
}

.level-bar {
    display: flex;
    justify-content: center;
}

.bar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 1px !important;
    width: 9%;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 10px;
    border-left-color: transparent;
    border-right: 10px;
    border-right-color: transparent;
    border-top: 10px;
    border-top-color: black;
    border-style: solid;
    border-bottom-style: none;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px;
    border-left-color: transparent;
    border-right: 10px;
    border-right-color: transparent;
    border-bottom: 10px;
    border-bottom-color: black;
    border-style: solid;
    border-top-style: none;
}

.triangle-none {
    height: 10px;
}

.level-selected {
    border-width: 1px;
    border-color: #FFFF00;
    border-style: solid;
}

.level-item {
    width: 100%;
    height: 18px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 1px;
}

.level-bar-level {
    width: 100%;
    font-size: 13px !important;
    padding: 0px;
    margin: 0px;
    text-align: center;
}
div.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 8px;
  color: white;
  z-index: 10;
  border-radius: 6px;
  font: 12px sans-serif;
}

.image-button {
    width: 43px;
    height: 43px;
    padding: 0px;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
}

.big-button {
    width: 80px;
    height: 80px;
    margin: 0;
}

.small-button {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 80px;
    left: 80px;
    margin: 0;
}

.percent-view {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 8px;
}
.question-bar {
  color: grey;
  margin-top: 0px;
  text-align: center;
}

.question-mission-bar {
  color: grey;
  margin-top: 10px;
  text-align: center;
}

.block-row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.description-block {
  font-style: italic;
  font-size: 16px;
}

.description-left {
  border-right: 1px solid grey;
}

.highlighted {
  color: red;
}

.scroll-bar {
  padding-left: 50px;
  left: 0;
  width: 100% !important;
  height: 400px !important;
  border-top: 4px solid grey;
  border-bottom: 4px solid grey;
}

.map-view-title {
  font-size: 16px;
  color: #d4d2d2;
  text-align: center;
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.manifesto-zone {
  height: 210px;
  text-align: left;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.manifesto-zone-context {
  height: 210px;
  width: 100%;
}

.end-zone-block {
  background-color: #777777;
}

.end-zone-extra {
  display: flex;
}

.end-zone-extra p {
  background-color: #777777;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
}
.end-zone-extra a:link {
  color: #fffdf3;
}
.end-zone-extra a:visited {
  color: #fffdf3;
}
.end-left-zone {
  text-align: center;
}

.end-zone-row {
  display: flex;
  justify-content: space-between;
  color: #fffdf3;

  height: 200px;
}

.video-zone {
  margin-top: 70px;
  height: 344px;
}

.treemap-zone {
  width: 100%;
  height: 750px;
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid grey;
}

@media only screen and (min-width: 750px) {
  .treemap-zone {
    width: 49%;
    display: block;
    border-bottom: 0px solid grey;
  }
  .end-zone-extra p {
    margin: auto;
  }
}

.main {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.title {
  text-align: center;
  color: #212529;
  font-size: 2rem;
  font-weight: 500;

  line-height: 2;
}

.date {
  color: grey;
  text-align: center;
}

.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 5px;
}
.imageInternal {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 5px;
}


#wall {
  position: relative;
  width: 100%;
  height: 150px;
  top: -10px;
  background: #fff;
  text-align: center;
  padding-top: 30px;
  font-family: Quicksand, Helvetica, sans;
  color: #FF5700;
}

#eye-l,
#eye-r {
  position: absolute;
  z-index: 20;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  margin-top: 5px;
  left: 5px;
  margin-left: -5px;
  -webkit-animation: search 2s infinite;
  box-sizing: border-box;
  border: 3px solid #FF5700;
}

#eye-r {
  margin-left: 5px;
}

#nose {
  position: relative;
  width: 15px;
  height: 15px;
  border: 3px solid #FF5700;
  border-radius: 50%;
  border-top-color: transparent;
  background: white;
  top: 12px;
  left: -7px;
  -webkit-transform: rotate(35deg);
  -webkit-animation: noser 2s infinite;
}

#mouth {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #FF5700;
  margin-top: 20px;
  float: left;
  margin-left: 3px;
  -webkit-animation: search 2s infinite;
}

#man {
  position: relative;
  width: 50px;
  height: 70px;
  border: 5px solid #FF5700;
  border-radius: 50%;
  margin-left: 50%;
  left: -25px;
  -webkit-animation: pop 8s infinite;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffdf3 !important;
  padding: 0 !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

