body {
  background: #fff;
  padding: 0;
  margin: 0;
}
@media (min-width: 0px) {
  .coreSupporterComment {
    position: absolute;
    max-width: 70%;
    left: 74px;
    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 470px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 94px;

    transform: translate(0px, -58px);
    float: left;
  }
}
@media (min-width: 520px) {
  .coreSupporterComment {
    position: absolute;
    width: 100%;
    left: 94px;
    transform: translate(0px, -58px);
    float: left;
  }
}

.coreSupporterComment #holder {
  width: 100%;
}

#holder > div {
  clear: both;
  padding: 0%;
  margin-left: 2px;
  margin-bottom: 0px;

  float: left;
  width: 96%;
}

label {
  display: inline;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fffdf3;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;

  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox + label:active,
.regular-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked + label {
  background-color: #fffdf3;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #4d4d4d;
}

.regular-checkbox:checked + label:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 5px;
  color: #4d4d4d;
}

.big-checkbox + label {
  padding: 18px;
}

.big-checkbox:checked + label:after {
  font-size: 28px;
  left: 6px;
}

.tag {
  font-family: Arial, sans-serif;
  width: 200px;
  position: relative;
  top: 5px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  float: left;
}

.radio-1 {
  width: 193px;
}

.button-holder {
  float: left;
}

/* RADIO */

.regular-radio {
  display: none;
}

.regular-radio + label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}

.regular-radio:checked + label:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #99a1a7;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-shadow: 0px;
  left: 3px;
  font-size: 32px;
}

.regular-radio:checked + label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1),
    inset 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.regular-radio + label:active,
.regular-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.big-radio + label {
  padding: 16px;
}

.big-radio:checked + label:after {
  width: 24px;
  height: 24px;
  left: 4px;
  top: 4px;
}

/* ------- IGNORE */

#header {
  width: 100%;
  margin: 0px auto;
}

#header #center {
  text-align: center;
}

#header h1 span {
  color: #000;
  display: block;
  font-size: 50px;
}

#header p {
  font-family: "Georgia", serif;
}
#header h1 {
  color: #892dbf;
  font: bold 40px "Bree Serif", serif;
}

#travel {
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  font-variant: normal;
  text-decoration: none;
  margin-bottom: 20px;
}

#travel a {
  font-family: "Georgia", serif;
  text-decoration: none;
  border-bottom: 1px solid #f9f9f9;
  color: #f9f9f9;
}
